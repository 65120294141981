<template>
  <div>
    <v-form @submit.prevent="loadData" id="filter" class="">
      <v-row justify="space-between">
        <v-col cols="12" lg="8">
          <atoms-text-field
            label="Cari Kuotasi"
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
            appendIcon="mdi-close"
          />
        </v-col>
        <v-col cols="12" lg="2" class="d-flex justify-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary"
            ><v-icon left>mdi-magnify</v-icon>Cari Kuotasi</atoms-button
          >
        </v-col>
        <v-col cols="12" lg="2">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getRequest()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort Tgl : {{ sort }}
          </atoms-button>
        </v-col>
      </v-row>
    </v-form>
    <v-row align="center" dense>
      <v-col cols="12" lg="auto" class="d-flex align-center grow py-0 my-0 ml-5">
        <v-checkbox v-model="enableDate" hide-spin-buttons /><atoms-text>Filter tanggal ?</atoms-text>
      </v-col>
      <v-scroll-y-transition>
        <v-col cols="12" v-if="enableDate" class="my-0">
          <v-row dense>
            <v-col cols="12" md="6">
              <atoms-date-picker
                label="Dari Tanggal"
                :modal="from.modal"
                :value="from.value"
                @change="
                  (modal, value) => {
                    from.modal = modal
                    from.value = value
                  }
                "
              />
            </v-col>
            <v-col cols="12" md="6">
              <atoms-date-picker
                label="Sampai Tanggal"
                :modal="to.modal"
                :value="to.value"
                @change="
                  (modal, value) => {
                    to.modal = modal
                    to.value = value
                  }
                "
              />
            </v-col>
          </v-row>
        </v-col>
      </v-scroll-y-transition>
    </v-row>

    <v-row align="center" dense>
      <v-col class="shrink" v-if="checkboxItems && checkboxItems.length > 0 && quotations && quotations.length > 0">
        <v-checkbox :disabled="mainLoading" v-model="checkAll" />
      </v-col>
      <v-col v-if="checkboxItems && checkboxItems.length > 0" cols="8" lg="2">
        <atoms-button
          :disabled="mainLoading"
          class="red darken-2 white--text"
          style="width: 100%"
          @click="removeBulk(checkboxItems)"
        >
          Hapus Permanen {{ checkboxItems.length || 0 }} Quotation
        </atoms-button>
      </v-col>
    </v-row>

    <div class="mt-4">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="quotations && quotations.length > 0">
          <div
            v-for="(quotation, i) in quotations || []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <v-row align="center">
              <v-col class="shrink">
                <v-checkbox :value="checkAll ? true : undefined" @click="addItems(quotation)" />
              </v-col>
              <v-col cols="8" lg="4">
                <atoms-text class="font-weight-bold">{{ (quotation && quotation.no) || 'No Quotation' }}</atoms-text>
                <atoms-text class="primary--text">{{
                  (quotation.client && quotation.client.name) || 'Nama Perusahaan'
                }}</atoms-text>
              </v-col>
              <v-col cols="12" lg="4" align="start">
                <atoms-text class="font-weight-bold">Dihapus pada tanggal : </atoms-text>
                <atoms-text class="primary--text">
                  {{ quotation._deletedDate ? $moment(quotation && quotation._deletedDate).format('dddd, DD MMMM YYYY') : "Tanggal Tidak Tercatat" }}
                </atoms-text>
              </v-col>
              <v-col align="end" class="">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="rounded-lg">
                    <v-list-item link :disabled="!quotation" @click="$window.open(`/cetak-kuotasi?type=quotation&no=${quotation?._id}`)" class="rounded-lg px-4">
                      <v-icon left>mdi-printer</v-icon>
                      <v-list-item-title class="px-2">Print Preview</v-list-item-title>
                    </v-list-item>
                    <v-list-item link :disabled="!quotation" @click="remove(quotation)" class="rounded-lg px-4">
                      <v-icon left>mdi-delete</v-icon>
                      <v-list-item-title class="px-2">Hapus Permanen </v-list-item-title>
                    </v-list-item>
                    <v-list-item link :disabled="!quotation" @click="restore(quotation)" class="rounded-lg px-4">
                      <v-icon left>mdi-file-restore-outline</v-icon>
                      <v-list-item-title class="px-2">Restore </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="px-0 pb-0">
              <v-col align="start" class="">
                <atoms-text v-if="quotation.deletedBy"
                  ><v-icon left small>mdi-account-sync</v-icon> Dihapus oleh
                  <span class="primary--text"
                    >{{ quotation.deletedBy.nickname || '-' }} ({{ quotation.deletedBy.email || '' }})</span
                  ></atoms-text
                >
              </v-col>
            </v-row>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination class="my-4" v-model="page" :length="$store.state.quotation.pagesLength"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-6">Tidak ada quotation yang ditemukan!</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </div>

    <!-- history -->
    <v-slide-x-reverse-transition>
      <organism-workers-history-balance
        v-if="showHistory"
        :data="showHistory"
        :pureEdit="true"
        @closed="
          () => {
            showHistory = null
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      term: '',
      page: 1,
      limit: 10,
      sort: '',
      quotations: [],
      checkboxItems: [],
      checkAll: false,
      enableDate: false,

      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      showHistory: null,
      constant: ['Desc', 'Asc'],
      reportLoading: false,
      mainLoading: false,
      exportData: {
        email: 'arsip.hayokerja@gmail.com',
      },
    }
  },
  watch: {
    checkAll: {
      handler(value) {
        if (!!value) {
          this.checkboxItems = this.quotations
        } else {
          this.checkboxItems = []
        }
      },
      deep: true,
    },
    page: {
      handler() {
        this.getRequest()
      },
      deep: true,
    },
  },
  async mounted() {
    this.sort = this.constant[0]
    this.loadData()
  },
  computed: {
    filterQuery: function () {
      let $or = undefined
      if (this.term) {
        $or = [...($or || []), { no: { $regex: this.term || '', $options: 'i' } }]
      }
      return {
        pipeline: [
          {
            $sort: {
              _deletedDate: this.sort === this.constant[0] ? -1 : 1,
            },
          },
          {
            $lookup: {
              from: 'Clients',
              localField: 'clientId',
              foreignField: '_id',
              as: 'client',
            },
          },
          {
            $unwind: {
              path: '$client',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              from: 'Users',
              localField: 'deletedBy',
              foreignField: '_id',
              as: 'deletedBy',
            },
          },
          {
            $unwind: {
              path: '$deletedBy',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $match: {
              $or,
              isDeleted: true,
              _deletedDate: this.enableDate
                ? {
                    $gte: new Date(new Date(this.from.value).setHours(0, 0, 0, 0)),
                    $lt: new Date(new Date(this.to.value).setHours(45, 0, 0, 0)),
                  }
                : undefined,
            },
          },
        ],
      }
    },
  },
  methods: {
    async loadData() {
      this.page = 1
      await this.getRequest()
    },
    async addItems(item) {
      if (!this.checkboxItems?.find((x) => x._id == item._id)) {
        this.checkboxItems.push(item)
      } else {
        this.checkboxItems = this.checkboxItems?.filter((x) => x._id != item._id)
      }
      if (this.checkboxItems?.length >= this.quotations?.length) {
        this.checkAll = true
      } else if (this.checkAll) {
        const temp = [...this.checkboxItems]
        this.checkAll = false
        this.checkboxItems = temp
      }
    },
    async removeBulk(payload) {
      this.mainLoading = true
      try {
        if (Array.isArray(payload)) {
          payload = payload?.filter((it) => !it?.removed)
        }
        if (Array.isArray(payload) && payload?.length > 0) {
          await this.$api.delete('Quotations/purgeDelete', {
            params: {
              _ids: JSON.stringify(payload?.map((it) => it?._id)),
            }
          })
        } else {
          alert('Tidak ada permintaan dari quotation yang Anda list')
        }
        this.checkAll = false
        this.checkboxItems = []
      } catch (error) {
        console.log(error)
        throw new Error(`Salah Satu Permintaan Gagal : ${error.message || error}`)
      } finally {
        await this.loadData()
        this.mainLoading = false
      }
    },
    async remove(payload) {
      this.mainLoading = true
      try {
        if (
          payload?._id &&
          confirm(
            `Apakah Anda yakin ingin menghapus quotation ${
              payload?.no || 'ini'
            } ?`,
          )
        ) {
          await this.$api.delete('Quotations/purgeDelete', {
            params: {
              _ids: JSON.stringify([payload._id]),
            }
          })
          this.checkAll = false
          this.checkboxItems = []
          await this.loadData()
        }
      } catch (error) {
        throw new Error(error)
      } finally {
        this.mainLoading = false
      }
    },
    async restore(payload) {
      this.mainLoading = true
      try {
        if (
          payload?._id &&
          confirm(
            `Apakah Anda yakin ingin mengembalikan quotation ${
              payload?.no || 'ini'
            } ?`,
          )
        ) {
          await this.$api.patch('Quotations/restoreQuotation', {
            _id: payload._id,
          })
          this.checkAll = false
          this.checkboxItems = []
          await this.loadData()
        }
      } catch (error) {
        throw new Error(error)
      } finally {
        this.mainLoading = false
      }
    },
    async getRequest() {
      this.mainLoading = true
      try {
        this.quotations = await this.$store.dispatch('quotation/getAll', {
          custom: this.filterQuery,
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        throw new Error(error)
      } finally {
        this.mainLoading = false
      }
    },
  },
}
</script>
